@tailwind base;
@tailwind components;
@tailwind utilities;

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


@layer components {
  .scrollbar::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f1f1f1;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 100vh;
    border: 3px solid #c1c1c1;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
}

@media print {
  @page {
    margin-left: 0.5in;
    margin-right: 0.5in;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}
